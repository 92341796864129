import appConfig from "../settings/appConfig"

const host = appConfig?.apiEndPoint

export const URLs = {
    MAILING_ADDRESS_GET_API : host+"/accountapi/Account/GetMailingAddress",
    MAILING_ADDRESS_ADD_UPDATE_API: host+"/accountapi/Account/AddUpdateAddress",
    ADDRESS_TYPE: host+"/accountapi/ListAddress/GetAllAddressType",
    ADDRESS_STATES: host+"/accountapi/ListAddress/GetAllState",
    ADDRESS_COUNTRIES: host+"/accountapi/ListAddress/GetAllCountry",
    ADDRESS_SUBMISSION: host+"/accountapi/AuditTrail/AddUpdateAuditTrailAddress",
    HOUSEHOLD_GET_API: host+"/accountapi/Household/GetHouseHoldDetails",
    ACCOUNT_DETAILS_GET_API: host+"/accountapi/AccountDetails/GetAccountDetails",
    ACCOUNT_DETAILS_ADD_UPDATE_API: host+"/accountapi/AccountDetails/AddUpdateAccountDetails",
    ACCOUNT_ROLES_GET_API: host+"/accountapi/Account/GetAccountRoles",
    GET_CONTEXT: host+"/clientlinkapi/GetContext",
    GET_AUDIT_TRAIL: host+"/accountapi/AuditTrail/GetAuditTrailByAccNum",
    ACCOUNT_DETAILS_SUBMISSION: host+"/accountapi/AuditTrail/AccountDetailsSubmissionProcess",
    APPROVE_REJECT: host+"/accountapi/AuditTrail/ApproveAccountInfo",
    DELETE_ALT_ADDRESS_BY_ID: host+"/accountapi/AuditTrail/DeleteAddress",
    ADDRESS_VALIDATION:host+"/clientapi/AddressDetails/AddressValidation",
    GET_INVESTMENT_DETAILS:host+"/accountapi/InvestmentProfile/GetInvestmentProfile",
    UPDATE_INVESTMENT_DETAILS:host+"/accountapi/InvestmentProfile/UpdateInvestmentSubmissionProcess",
    AUDIT_TRAIL_CANCEL_REQUEST: host+"/accountapi/AuditTrail/AuditTrialCancellationOption",
    DELETE_CONTACT_BY_ID: host+"/accountapi/AccountContactType/DeleteContactInfo",
    DELETE_EMAIL_BY_ID: host + "/accountapi/AccountContactType/DeleteEmailDetails",
    ACCOUNT_RESTRICTIONS_TYPE_GET_API: host + "/accountapi/AccountRestriction/",
    ACCOUNT_RESTRICTIONS_TYPE_ADD_UPDATE_API: host + "/accountapi/AccountRestriction/AddUpdate/",
    ACCOUNT_RESTRICTIONS_TYPE_ADD_UPDATE_OTHER_API: host + "/accountapi/AccountRestriction/AddUpdateUserDefined/",
    ACCOUNT_RESTRICTIONS_AUDIT: host + "/accountapi/AccountRestriction/audit/",
    ACCOUNT_LOAN_DETAILS_GET_ALL: host + '/accountapi/SuperNova/',
    ACCOUNT_LOAN_DETAILS_DROPDOWN: host + '/accountapi/SuperNova/GetAllPledgedStatus',
    ACCOUNT_LOAN_DETAILS_SUBMISSION: host + '/accountapi/SuperNova/AddUpdatePledgedStatus',
    GET_BENEFICIARY_DETAILS:host + "/accountapi/AccountBeneficiaries/GetAllAccountBeneficiary",
}