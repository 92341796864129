import {
    WDNewProfileSidebarMenu,
    WDStyledCancelButtonLabel,
    WDStyledSubSectionTitle,
    WDStyledTableHead,
} from '../../components/ui/WDTypography'
import {
    ButtonRow,
    DividerWrapper2,
    PrimaryButtonWrapper,
    SecondaryButtonWrapper,
    SubHeadingWrapper,
    TextboxWrapper2,
} from '../investment/styles'
import {
    WDStyledCancelButton,
    WDStyledSaveButton,
} from '../../components/ui/WDButtons'
import { COLORS } from '../../theme/Colors'
import { useContext, useState, useEffect } from 'react'
import { ApprovalContext } from '../../services/approvalContext/ApprovalContext'
import Divider from '../../components/divider/Divider'
import {
    Box,
    LabelWrap,
    FlexItem,
    FlexItem2,
    Row,
    MandatoryLabel,
    LabelWrap2,
    LabelWrap3,
    FlexItem3,
    CloseWrapper,
    CloseWrapper1,
} from './styles'
import Label from '../../components/label/Label'
import { messages } from '../../shared/constants'
import { InlineErrorWrapper } from '../mailingAddress/styles'
import {
    BeneficiaryContext,
    BeneficiaryUpdate,
    OldBeneficiaryUpdate,
    TableData,
} from '../../services/beneficiaryContext/BeneficiaryContext'
import { DropdownField } from '../dropdownField/DropdownField'
import { InputField } from '../inputField/InputField'
import { Tooltip, IconButton } from '@chakra-ui/react'
import { PlusSquareIcon, SmallCloseIcon } from '@chakra-ui/icons'
import BeneficiarySubmitionPage from './BeneficiarySubmisionPage'
import BeneficiarySubmisionPage from './BeneficiarySubmisionPage'
import { SvgCancel } from '../../components/svg/SvgCancel'
import { SvgCancel24 } from '../../components/svg/SvgCancel24'
import { data } from '@remix-run/router/dist/utils'

export const BeneficiaryEdit = () => {
    const BeneficiaryDetailsValues = useContext(BeneficiaryContext)
    const [primaryFirstNameErr, setPrimaryFirstNameErr] = useState<any>({})
    const [primaryLastNameErr, setPrimaryLastNameErr] = useState<any>({})
    const [primarySSNTINErr, setPrimarySSNTINErr] = useState<any>({})
    const [primaryDateOfBirthErr, setPrimaryDateOfBirthErr] = useState<any>({})
    const [primaryRelationshipErr, setPrimaryRelationshipErr] = useState<any>({})
    const [primaryBenefitsErr, setPrimaryBenefitsErr] = useState<any>({})
    const [primaryBenefitsRequiredErr, setPrimaryBenefitsRequiredErr] =
        useState<any>({})
    const [contingentBenefitsRequiredErr, setcontingentBenefitsRequiredErr] =
        useState<any>({})
    const [contingentFirstNameErr, setContingentFirstNameErr] = useState<any>({})
    const [contingentLastNameErr, setContingentLastNameErr] = useState<any>({})
    const [contingentSSNTINErr, setContingentSSNTINErr] = useState<any>({})
    const [contingentDateOfBirthErr, setContingentDateOfBirthErr] = useState<any>(
        {}
    )
    const [contingentRelationshipErr, setContingentRelationshipErr] =
        useState<any>({})
    const [contingentBenefitsErr, setContingentBenefitsErr] = useState<any>({})
    const [totalPrimaryBenefits, setTotalPrimaryBenefits] = useState<string>()
    const [totalContingentBenefits, setTotalContingentBenefits] = useState<string>()
    const [totalPrimaryBenefitsErr, setTotalPrimaryBenefitsErr] =
        useState<boolean>(false)
    const [totalContingentBenefitsErr, setTotalContingentBenefitsErr] =
        useState<boolean>(false)
    const [relationship, setRelationship] = useState('Select')
    const approval = useContext(ApprovalContext)
    const [primaryNewSSN, setPrimaryNewSSN] = useState<any>({})
    const [contingentNewSSN, setContingentNewSSN] = useState<any>({})

    const [oldPrimaryBeneficiaryData, setOldPrimaryBeneficiaryData] = useState<OldBeneficiaryUpdate[]>(messages.Beneficiary_Designation1.primary_data)
    const [primaryBeneficiaryData, setprimaryBeneficiaryData] = useState<BeneficiaryUpdate[]>(messages.Beneficiary_Designation.primary_data)

    const [oldContingentData, setOldContingentData] = useState<OldBeneficiaryUpdate[]>((messages.Beneficiary_Designation1.contingent_data))
    const [contingentData, setContingentData] = useState<BeneficiaryUpdate[]>((messages.Beneficiary_Designation.contingent_data))

    const [accountDetailsTestValues, setAccountDetailsTestValues] = useState({})
    const [primaryIndices, setPrimaryIndices] = useState<number[]>([])
    const [contingentIndices, setContingentIndices] = useState<number[]>([])

    const addBeneficiary = (dataArray: BeneficiaryUpdate[]) => {
        if (dataArray === primaryBeneficiaryData) {
            if (primaryBeneficiaryData.length < 20) {
                const totalBenefits = primaryBeneficiaryData.reduce((total, data) => total + Number(data.benefits), 0)
                setTotalPrimaryBenefits(String(totalBenefits))
                setprimaryBeneficiaryData([
                    ...primaryBeneficiaryData,
                    {
                        partyId: "",
                        firstName: "",
                        middleName: "",
                        lastName: "",
                        fullName: "",
                        ssnOrTin: "",
                        dateOfBirth: "",
                        relationship: "",
                        benefits: "",
                        addressLine1: "",
                        addressLine2: "",
                        city: "",
                        state: "",
                        country: "",
                        zipCode: "",
                        rightOfRepresentation: "",

                    },
                ])
            } else {
                console.log("can't add more beneficiaries")
            }
        } else {
            if (contingentData.length < 20) {
                const totalBenefits = contingentData.reduce((total, data) => total + Number(data.benefits), 0)
                setTotalContingentBenefits(String(totalBenefits))
                setContingentData([
                    ...contingentData,
                    {
                        partyId: "",
                        firstName: "",
                        middleName: "",
                        lastName: "",
                        fullName: "",
                        ssnOrTin: "",
                        dateOfBirth: "",
                        relationship: "",
                        benefits: "",
                        addressLine1: "",
                        addressLine2: "",
                        city: "",
                        state: "",
                        country: "",
                        zipCode: "",
                        rightOfRepresentation: "",
                    },
                ])
            } else {
                console.log("can't add more beneficiaries")
            }
        }
    }

    const cancelFunction = () => {
        // setprimaryBeneficiaryData(messages.Beneficiary_Designation1.primary_data)
        primaryBeneficiaryData.splice(0)
        primaryBeneficiaryData.push(messages.Beneficiary_Designation1.primary_data[0])
        setContingentData([...oldContingentData])
        setPrimaryIndices([])
        setContingentIndices([])
        approval?.setIsActiveInv(!approval?.isActiveInv)
    }

    const deleteRecord = (index: any, dataArray: BeneficiaryUpdate[]) => {
        if (dataArray === primaryBeneficiaryData) {
            dataArray.splice(index, 1)
            primaryBeneficiaryData.splice(index, 1)
            oldPrimaryBeneficiaryData.splice(index, 1)
            setprimaryBeneficiaryData([...primaryBeneficiaryData])
            setOldPrimaryBeneficiaryData([...primaryBeneficiaryData])
        }
        else {
            dataArray.splice(index, 1)
            contingentData.splice(index, 1)
            oldContingentData.splice(index, 1)
            setContingentData([...contingentData])
            setOldContingentData([...contingentData])
        }
    }

    // useEffect(() => {
    //     if (primaryBeneficiaryData !== undefined) {
    //         setprimaryBeneficiaryData([...primaryBeneficiaryData])
    //     }
    // }, [primaryBeneficiaryData.length])

    // useEffect(() => {
    //     if (contingentData !== undefined) {
    //         setContingentData([...contingentData])
    //     }
    // }, [contingentData.length])

    const checkFirstName = (dataArray: BeneficiaryUpdate[]) => {
        const obj: any = {}
        dataArray?.map((item: any, index: any) => {
            if (item.firstName === '') {
                obj[index] = true
            }
        })
        if (dataArray === primaryBeneficiaryData) {
            setPrimaryFirstNameErr(obj)
        } else {
            setContingentFirstNameErr(obj)
        }
    }

    const checkLastName = (dataArray: BeneficiaryUpdate[]) => {
        const obj: any = {}
        dataArray?.map((item: any, index: any) => {
            if (item.lastName === '') {
                obj[index] = true
            }
        })
        if (dataArray === primaryBeneficiaryData) {
            setPrimaryLastNameErr(obj)
        } else {
            setContingentLastNameErr(obj)
        }
    }

    const checkDateOfBirth = (dataArray: BeneficiaryUpdate[]) => {
        const obj: any = {}
        dataArray?.map((item: any, index: any) => {
            if (item.dateOfBirth === null || item.dateOfBirth === '') {
                obj[index] = true
            }
        })
        if (dataArray === primaryBeneficiaryData) {
            setPrimaryDateOfBirthErr(obj)
        } else {
            setContingentDateOfBirthErr(obj)
        }
    }

    const checkRelationshipValue = (dataArray: BeneficiaryUpdate[]) => {
        const obj: any = {}
        dataArray?.map((item: any, index: any) => {
            if (item.relationship === '' || item.relationship === 'Select') {
                obj[index] = true
            } else {
                obj[index] = false
            }
        })
        if (dataArray === primaryBeneficiaryData) {
            setPrimaryRelationshipErr(obj)
        } else {
            setContingentRelationshipErr(obj)
        }
    }

    const checkBenefits = (dataArray: BeneficiaryUpdate[]) => {
        const regex = /^(?:0|[1-9]\d+|)?(?:.?\d{0,1})?$/

        const obj: any = {}
        const reqObj: any = {}
        dataArray?.map((item: any, index: any) => {
            if (String(item.benefits)?.match(regex)) {
                obj[index] = false
            }
            if (item.benefits === '') {
                reqObj[index] = true
            }
        })
        if (dataArray === primaryBeneficiaryData) {
            setPrimaryBenefitsErr(obj)
            setPrimaryBenefitsRequiredErr(reqObj)
        } else {
            setContingentBenefitsErr(obj)
            setcontingentBenefitsRequiredErr(reqObj)
        }
    }

    const checkSSNTIN = (dataArray: BeneficiaryUpdate[]) => {
        const obj: any = {}
        dataArray?.map((item: any, index: any) => {
            if (item?.ssnOrTin) {
                if (item?.ssnOrTin?.length != 11) {
                    obj[index] = true
                } else {
                    obj[index] = false
                }
            } else {
                obj[index] = true
            }
        })
        if (dataArray === primaryBeneficiaryData) {
            setPrimarySSNTINErr(obj)
        } else {
            setContingentSSNTINErr(obj)
        }
    }

    const formatSSN = (value: string) => {
        const number = value?.replace(/[^\d]/g, '')
        const numberLength = number?.length
        if (numberLength < 4) return number
        if (numberLength < 6) {
            return `${number.slice(0, 3)}-${number?.slice(3, 5)}`
        }
        return `${number?.slice(0, 3)}-${number?.slice(3, 5)}-${number?.slice(5, 9)}`
    }

    const handleChange = (
        e: any,
        name: string,
        index: any,
        dataArray: BeneficiaryUpdate[]
    ) => {
        if (dataArray === primaryBeneficiaryData) {
            setPrimaryIndices([...primaryIndices, index])
        }
        else {
            setContingentIndices([...contingentIndices, index])
        }
        const values = [...dataArray]
        if (e !== undefined) {
            if (name === 'PartyId') {
                values[index].partyId = e
                setprimaryBeneficiaryData([...primaryBeneficiaryData])
            }
            else if (name === 'First Name') {
                values[index].firstName = e
                setprimaryBeneficiaryData([...primaryBeneficiaryData])
                checkFirstName(dataArray)
            } else if (name === 'Middle Name') {
                values[index].middleName = e
            } else if (name === 'Last Name') {
                values[index].lastName = e
                checkLastName(dataArray)
            } else if (name === 'SSN/TIN') {
                values[index].ssnOrTin = formatSSN(e)
                const obj: any = {}
                dataArray?.map((item: any, index: any) => {
                    obj[index] = item.ssnOrTin.replace(
                        /(\d{3})(\d{2})(\d{4})/,
                        '$1-$2-$3'
                    )
                })
                if (dataArray === primaryBeneficiaryData) {
                    setPrimaryNewSSN(obj)
                } else {
                    setContingentNewSSN(obj)
                }
                checkSSNTIN(dataArray)
            } else if (name === 'dateOfBirth') {
                values[index].dateOfBirth = e
                checkDateOfBirth(dataArray)
            } else if (name === 'Relationship') {
                const relValue = Object.values(e)[0] as string
                values[index].relationship = relValue
                setRelationship(relValue)
                // data.relationship = e
                checkRelationshipValue(dataArray)
            } else if (name === 'Address Line 1') {
                values[index].addressLine1 = e
            } else if (name === 'Address Line 2') {
                values[index].addressLine2 = e
            } else if (name === 'City') {
                values[index].city = e
            } else if (name === 'State') {
                values[index].state = Object.values(e)[0] as string
            } else if (name === 'Country') {
                values[index].country = Object.values(e)[0] as string
            } else if (name === 'ZipCode') {
                values[index].zipCode = e
            } else if (name === 'Right Of Representation') {
                values[index].rightOfRepresentation = Object.values(e)[0] as string
            } else if (name === 'Benefits') {
                values[index].benefits = e
                let total = 0
                dataArray.forEach(({ benefits }) => {
                    total += Number(benefits)
                })
                values[index].totalBenefits = String(total)
                if (dataArray === primaryBeneficiaryData) {
                    setTotalPrimaryBenefits(String(total))
                } else {
                    setTotalContingentBenefits(String(total))
                }

                checkBenefits(dataArray)

                if (dataArray === primaryBeneficiaryData) {
                    if (Number(total) !== 100) {
                        setTotalPrimaryBenefitsErr(true)
                    } else {
                        setTotalPrimaryBenefitsErr(false)
                    }
                } else {
                    if (Number(total) !== 100) {
                        setTotalContingentBenefitsErr(true)
                    } else {
                        setTotalContingentBenefitsErr(false)
                    }
                }
            }
        }
    }
    const primaryData_titles = {
        partyId: "PartyId",
        firstName: "First Name",
        middleName: "Middle Name",
        lastName: "Last Name",
        fullName: "Full Name",
        ssnOrTin: "SSN/TIN",
        dateOfBirth: "Date Of Birth",
        relationship: "Relationship",
        benefits: "%Benefits",
        addressLine1: "Address Line1",
        addressLine2: "Address Line2",
        city: "City",
        state: "State",
        country: "Country",
        zipCode: "ZipCode",
        rightOfRepresentation: "Right Of Representation",
        totalBenefits: "Total Benefits"
    }

    const primaryData_diff = (
        obj1: BeneficiaryUpdate[],
        obj2: BeneficiaryUpdate[],
        fieldObj: BeneficiaryUpdate
    ) => {
        const differences: TableData[] = []
        const primaryIndices1 = [...new Set(primaryIndices)]
        for (const i of primaryIndices1) {
            if (Number(obj2[i].benefits) !== 0) {
                for (const key in obj1[i]) {
                    if (
                        Object.hasOwnProperty.call(obj1[i], key) &&
                        Object.hasOwnProperty.call(obj2[i], key)
                    ) {
                        // if (obj1[i][key as keyof BeneficiaryUpdate] !== obj2[i][key as keyof BeneficiaryUpdate]) {
                        differences.push({
                            field: fieldObj[key as keyof BeneficiaryUpdate],
                            oldValue: obj1[i][key as keyof BeneficiaryUpdate]
                                ? obj1[i][key as keyof BeneficiaryUpdate]
                                : '--',
                            newValue: obj2[i][key as keyof BeneficiaryUpdate]
                                ? obj2[i][key as keyof BeneficiaryUpdate]
                                : '--',
                        })
                        // }
                    }
                }
            }
        }
        return differences
    }
    const contingentData_diff = (
        obj1: BeneficiaryUpdate[],
        obj2: BeneficiaryUpdate[],
        fieldObj: BeneficiaryUpdate
    ) => {
        const differences: TableData[] = []
        const contingentIndices1 = [...new Set(contingentIndices)]
        for (const i of contingentIndices1) {
            if (Number(obj2[i].benefits) !== 0) {
                for (const key in obj1[i]) {
                    if (
                        Object.hasOwnProperty.call(obj1[i], key) &&
                        Object.hasOwnProperty.call(obj2[i], key)
                    ) {
                        // if (obj1[i][key as keyof BeneficiaryUpdate] !== obj2[i][key as keyof BeneficiaryUpdate]) {
                        differences.push({
                            field: fieldObj[key as keyof BeneficiaryUpdate],
                            oldValue: obj1[i][key as keyof BeneficiaryUpdate]
                                ? obj1[i][key as keyof BeneficiaryUpdate]
                                : '--',
                            newValue: obj2[i][key as keyof BeneficiaryUpdate]
                                ? obj2[i][key as keyof BeneficiaryUpdate]
                                : '--',
                        })
                    }
                    // }
                }
            }
        }
        return differences
    }
    const save = () => {
        checkFirstName(primaryBeneficiaryData)
        checkFirstName(contingentData)
        checkLastName(primaryBeneficiaryData)
        checkLastName(contingentData)
        checkDateOfBirth(primaryBeneficiaryData)
        checkDateOfBirth(contingentData)
        checkRelationshipValue(primaryBeneficiaryData)
        checkRelationshipValue(contingentData)
        checkSSNTIN(primaryBeneficiaryData)
        checkSSNTIN(contingentData)
        checkBenefits(primaryBeneficiaryData)
        checkBenefits(contingentData)
        if (oldPrimaryBeneficiaryData.length !== primaryBeneficiaryData.length) {
            for (let i = oldPrimaryBeneficiaryData.length + 1; i <= primaryBeneficiaryData.length; i++) {
                oldPrimaryBeneficiaryData.push({
                    partyId: "",
                    firstName: "",
                    middleName: "",
                    lastName: "",
                    fullName: "",
                    ssnOrTin: "",
                    dateOfBirth: "",
                    relationship: "",
                    benefits: "",
                    addressLine1: "",
                    addressLine2: "",
                    city: "",
                    state: "",
                    country: "",
                    zipCode: "",
                    rightOfRepresentation: "",
                    totalBenefits: ""
                })
            }
        }
        if (oldContingentData.length !== contingentData.length) {
            for (let i = oldContingentData.length + 1; i <= contingentData.length; i++) {
                oldContingentData.push({
                    partyId: "",
                    firstName: "",
                    middleName: "",
                    lastName: "",
                    fullName: "",
                    ssnOrTin: "",
                    dateOfBirth: "",
                    relationship: "",
                    benefits: "",
                    addressLine1: "",
                    addressLine2: "",
                    city: "",
                    state: "",
                    country: "",
                    zipCode: "",
                    rightOfRepresentation: "",
                    totalBenefits: ""
                })
            }
        }
        const data1 = primaryData_diff(
            oldPrimaryBeneficiaryData,
            primaryBeneficiaryData,
            primaryData_titles
        )
        const data2 = contingentData_diff(
            oldContingentData,
            contingentData,
            primaryData_titles
        )
        if (!Object.values(primaryFirstNameErr).some(x => x === true) && !Object.values(contingentFirstNameErr).some(x => x === true) &&
            !Object.values(primaryLastNameErr).some(x => x === true) && !Object.values(contingentLastNameErr).some(x => x === true) &&
            !Object.values(primarySSNTINErr).some(x => x === true) && !Object.values(contingentSSNTINErr).some(x => x === true) &&
            !Object.values(primaryDateOfBirthErr).some(x => x === true) && !Object.values(contingentDateOfBirthErr).some(x => x === true) &&
            !Object.values(primaryRelationshipErr).some(x => x === true) && !Object.values(contingentRelationshipErr).some(x => x === true) &&
            !Object.values(primaryBenefitsErr).some(x => x === true) && !Object.values(contingentBenefitsErr).some(x => x === true) &&
            !totalPrimaryBenefitsErr && !totalContingentBenefitsErr && (data1.length !== 0 || data2.length !== 0)) {
            BeneficiaryDetailsValues?.setPrimaryBeneficiaryUpdatedData(data1)
            BeneficiaryDetailsValues?.setContingentUpdatedData(data2)
            BeneficiaryDetailsValues?.setSubmissionPopUp(true)
        }
    }

    const handleDropDownChange = (
        e: string | undefined | number,
        data: any,
        dataArray: BeneficiaryUpdate[]
    ) => {
        if (e !== undefined) {
            const relValue = Object.values(e)[0]
            setRelationship(relValue)
            data.relationship = e
            checkRelationshipValue(dataArray)
        }
    }

    const relationshipOptions = [
        {
            value: 'Spouse',
            label: 'Spouse',
        },
        {
            value: 'Spouse > 10 years',
            label: 'Spouse > 10 years',
        },
        {
            value: 'Other',
            label: 'Other',
        },
    ]

    const rightOfRepresentationOptions = [
        {
            value: 'Yes',
            label: 'Yes',
        },
        {
            value: 'No',
            label: 'No',
        },
    ]

    const stateOptions = [
        {
            value: 'New York',
            label: 'New York',
        },
        {
            value: 'California',
            label: 'California',
        },
        {
            value: 'Texas',
            label: 'Texas',
        },
    ]

    const countryOptions = [
        {
            value: 'United State Of America',
            label: 'United State Of America',
        },
        {
            value: 'India',
            label: 'India',
        },
        {
            value: 'United Kingdom',
            label: 'United Kingdom',
        },
    ]

    const addRow = (index: any, data: any, dataArray: BeneficiaryUpdate[]) => {
        return (
            <>
                <Row key={index}>
                    <FlexItem2>
                        <InputField
                            label='PartyId'
                            onChange={(e) => handleChange(e, 'PartyId', index, dataArray)}
                            textboxDefaultValue={data.partyId}
                        />
                    </FlexItem2>
                    <FlexItem2>
                        <InputField
                            label='First Name'
                            mandatory={true}
                            onChange={(e) => handleChange(e, 'First Name', index, dataArray)}
                            textboxDefaultValue={data.firstName}
                            borderColor={
                                (
                                    dataArray === primaryBeneficiaryData
                                        ? primaryFirstNameErr[index]
                                        : contingentFirstNameErr[index]
                                )
                                    ? '#BA0517'
                                    : '#CBD5E1'
                            }
                            backgroundColor={
                                (
                                    dataArray === primaryBeneficiaryData
                                        ? primaryFirstNameErr[index]
                                        : contingentFirstNameErr[index]
                                )
                                    ? '#FEF1EE'
                                    : '#FFFFFF'
                            }
                        />
                        {(dataArray === primaryBeneficiaryData
                            ? primaryFirstNameErr[index]
                            : contingentFirstNameErr[index]) && (
                                <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                                    <Label
                                        fontFamily={'SourceSansPro-Regular'}
                                        fontSize={'14px'}
                                        fontStyle={'normal'}
                                        fontWeight={400}
                                        lineHeight={'16px'}
                                        color={'#BA0517'}
                                    >
                                        {(
                                            dataArray === primaryBeneficiaryData
                                                ? primaryFirstNameErr[index]
                                                : contingentFirstNameErr[index]
                                        )
                                            ? messages.address_ui.error_messages.required_field
                                            : ''}
                                    </Label>
                                </InlineErrorWrapper>
                            )}
                    </FlexItem2>
                    <FlexItem2>
                        <InputField
                            label='Middle Name'
                            onChange={(e) => handleChange(e, 'Middle Name', index, dataArray)}
                            textboxDefaultValue={data.middleName}
                        />
                    </FlexItem2>
                    <FlexItem2>
                        <InputField
                            label='Last Name'
                            mandatory={true}
                            onChange={(e) => handleChange(e, 'Last Name', index, dataArray)}
                            textboxDefaultValue={data.lastName}
                            borderColor={
                                (
                                    dataArray === primaryBeneficiaryData
                                        ? primaryLastNameErr[index]
                                        : contingentLastNameErr[index]
                                )
                                    ? '#BA0517'
                                    : '#CBD5E1'
                            }
                            backgroundColor={
                                (
                                    dataArray === primaryBeneficiaryData
                                        ? primaryLastNameErr[index]
                                        : contingentLastNameErr[index]
                                )
                                    ? '#FEF1EE'
                                    : '#FFFFFF'
                            }
                        />
                        {(dataArray === primaryBeneficiaryData
                            ? primaryLastNameErr[index]
                            : contingentLastNameErr[index]) && (
                                <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                                    <Label
                                        fontFamily={'SourceSansPro-Regular'}
                                        fontSize={'14px'}
                                        fontStyle={'normal'}
                                        fontWeight={400}
                                        lineHeight={'16px'}
                                        color={'#BA0517'}
                                    >
                                        {(
                                            dataArray === primaryBeneficiaryData
                                                ? primaryLastNameErr[index]
                                                : contingentLastNameErr[index]
                                        )
                                            ? messages.address_ui.error_messages.required_field
                                            : ''}
                                    </Label>
                                </InlineErrorWrapper>
                            )}
                    </FlexItem2>
                </Row>
                <Row key={index}>
                    <FlexItem2>
                        <InputField
                            label='Full Name'
                            onChange={(e) => handleChange(e, 'Full Name', index, dataArray)}
                            textboxDefaultValue={data.fullName}
                            textboxValue={data.middleName !== '' ? data.firstName + " " + data.middleName + " " + data.lastName
                                : data.firstName + " " + data.lastName}
                            disabled={true}
                        />
                    </FlexItem2>
                    <FlexItem2>
                        <InputField
                            label='SSN/TIN'
                            onChange={(e) => handleChange(e, 'SSN/TIN', index, dataArray)}
                            textboxDefaultValue={formatSSN(data.ssnOrTin)}
                            mandatory={true}
                            textboxValue={
                                dataArray === primaryBeneficiaryData
                                    ? primaryNewSSN[index]
                                    : contingentNewSSN[index]
                            }
                            borderColor={
                                (
                                    dataArray === primaryBeneficiaryData
                                        ? primarySSNTINErr[index]
                                        : contingentSSNTINErr[index]
                                )
                                    ? '#BA0517'
                                    : '#CBD5E1'
                            }
                            backgroundColor={
                                (
                                    dataArray === primaryBeneficiaryData
                                        ? primarySSNTINErr[index]
                                        : contingentSSNTINErr[index]
                                )
                                    ? '#FEF1EE'
                                    : '#FFFFFF'
                            }
                        />
                        {(dataArray === primaryBeneficiaryData
                            ? primarySSNTINErr[index]
                            : contingentSSNTINErr[index]) && (
                                <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                                    <Label
                                        fontFamily={'SourceSansPro-Regular'}
                                        fontSize={'14px'}
                                        fontStyle={'normal'}
                                        fontWeight={400}
                                        lineHeight={'16px'}
                                        color={'#BA0517'}
                                    >
                                        {(
                                            dataArray === primaryBeneficiaryData
                                                ? primarySSNTINErr[index]
                                                : contingentSSNTINErr[index]
                                        )
                                            ? messages.beneficiary_ui.ssn_error.ssn_invalid
                                            : ''}
                                    </Label>
                                </InlineErrorWrapper>
                            )}
                    </FlexItem2>
                    <FlexItem2>
                        <InputField
                            label='Date Of Birth'
                            mandatory={true}
                            onChange={(e) => handleChange(e, 'dateOfBirth', index, dataArray)}
                            type="Date"
                            textboxDefaultValue={data.dateOfBirth}
                            borderColor={
                                (
                                    dataArray === primaryBeneficiaryData
                                        ? primaryDateOfBirthErr[index]
                                        : contingentDateOfBirthErr[index]
                                )
                                    ? '#BA0517'
                                    : '#CBD5E1'
                            }
                            backgroundColor={
                                (
                                    dataArray === primaryBeneficiaryData
                                        ? primaryDateOfBirthErr[index]
                                        : contingentDateOfBirthErr[index]
                                )
                                    ? '#FEF1EE'
                                    : '#FFFFFF'
                            }
                        />
                        {(dataArray === primaryBeneficiaryData
                            ? primaryDateOfBirthErr[index]
                            : contingentDateOfBirthErr[index]) && (
                                <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                                    <Label
                                        fontFamily={'SourceSansPro-Regular'}
                                        fontSize={'14px'}
                                        fontStyle={'normal'}
                                        fontWeight={400}
                                        lineHeight={'16px'}
                                        color={'#BA0517'}
                                    >
                                        {(
                                            dataArray === primaryBeneficiaryData
                                                ? primaryDateOfBirthErr[index]
                                                : contingentDateOfBirthErr[index]
                                        )
                                            ? messages.address_ui.error_messages.required_field
                                            : ''}
                                    </Label>
                                </InlineErrorWrapper>
                            )}
                    </FlexItem2>
                    <FlexItem2>
                        <DropdownField
                            label='Relationship'
                            mandatory={true}
                            options={relationshipOptions}
                            defaultValue={{
                                value: data.relationship,
                                label: data.relationship,
                            }}
                            onChange={(e) => handleChange(e, 'Relationship', index, dataArray)}
                            borderColor={
                                (
                                    dataArray === primaryBeneficiaryData
                                        ? primaryRelationshipErr[index]
                                        : contingentRelationshipErr[index]
                                )
                                    ? '#BA0517'
                                    : '#CBD5E1'
                            }
                            backgroundColor={
                                (
                                    dataArray === primaryBeneficiaryData
                                        ? primaryRelationshipErr[index]
                                        : contingentRelationshipErr[index]
                                )
                                    ? '#FEF1EE'
                                    : '#FFFFFF'
                            }
                        />
                        {(dataArray === primaryBeneficiaryData
                            ? primaryRelationshipErr[index]
                            : contingentRelationshipErr[index]) && (
                                <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                                    <Label
                                        fontFamily={'SourceSansPro-Regular'}
                                        fontSize={'14px'}
                                        fontStyle={'normal'}
                                        fontWeight={400}
                                        lineHeight={'16px'}
                                        color={'#BA0517'}
                                    >
                                        {(
                                            dataArray === primaryBeneficiaryData
                                                ? primaryRelationshipErr[index]
                                                : contingentRelationshipErr[index]
                                        )
                                            ? messages.address_ui.error_messages.required_field
                                            : ''}
                                    </Label>
                                </InlineErrorWrapper>
                            )}
                    </FlexItem2>
                </Row>
                <Row key={index}>
                    <FlexItem2>
                        <InputField
                            label='% Benefits'
                            mandatory={true}
                            onChange={(e) => handleChange(e, 'Benefits', index, dataArray)}
                            textboxDefaultValue={data.benefits}
                            borderColor={
                                (
                                    dataArray === primaryBeneficiaryData
                                        ? primaryBenefitsErr[index] ||
                                        primaryBenefitsRequiredErr[index]
                                        : contingentBenefitsErr[index] ||
                                        contingentBenefitsRequiredErr[index]
                                )
                                    ? '#BA0517'
                                    : '#CBD5E1'
                            }
                            backgroundColor={
                                (
                                    dataArray === primaryBeneficiaryData
                                        ? primaryBenefitsErr[index] ||
                                        primaryBenefitsRequiredErr[index]
                                        : contingentBenefitsErr[index] ||
                                        contingentBenefitsRequiredErr[index]
                                )
                                    ? '#FEF1EE'
                                    : '#FFFFFF'
                            }
                        />
                        {(dataArray === primaryBeneficiaryData
                            ? primaryBenefitsErr[index]
                            : contingentBenefitsErr[index]) && (
                                <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                                    <Label
                                        fontFamily={'SourceSansPro-Regular'}
                                        fontSize={'14px'}
                                        fontStyle={'normal'}
                                        fontWeight={400}
                                        lineHeight={'16px'}
                                        color={'#BA0517'}
                                    >
                                        {(
                                            dataArray === primaryBeneficiaryData
                                                ? primaryBenefitsErr[index]
                                                : contingentBenefitsErr[index]
                                        )
                                            ? messages.beneficiary_ui.benefits_error.benefits_invalid
                                            : ''}
                                    </Label>
                                </InlineErrorWrapper>
                            )}
                        {(dataArray === primaryBeneficiaryData
                            ? primaryBenefitsRequiredErr[index]
                            : contingentBenefitsRequiredErr[index]) && (
                                <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                                    <Label
                                        fontFamily={'SourceSansPro-Regular'}
                                        fontSize={'14px'}
                                        fontStyle={'normal'}
                                        fontWeight={400}
                                        lineHeight={'16px'}
                                        color={'#BA0517'}
                                    >
                                        {(
                                            dataArray === primaryBeneficiaryData
                                                ? primaryBenefitsRequiredErr[index]
                                                : contingentBenefitsRequiredErr[index]
                                        )
                                            ? messages.address_ui.error_messages.required_field
                                            : ''}
                                    </Label>
                                </InlineErrorWrapper>
                            )}
                    </FlexItem2>
                    <FlexItem2>
                        <InputField
                            label='Address Line 1'
                            onChange={(e) => handleChange(e, 'Address Line 1', index, dataArray)}
                            textboxDefaultValue={data.addressLine1}
                        />
                    </FlexItem2>
                    <FlexItem2>
                        <InputField
                            label='Address Line 2'
                            onChange={(e) => handleChange(e, 'Address Line 2', index, dataArray)}
                            textboxDefaultValue={data.addressLine2}
                        />
                    </FlexItem2>
                    <FlexItem2>
                        <InputField
                            label='City'
                            onChange={(e) => handleChange(e, 'City', index, dataArray)}
                            textboxDefaultValue={data.city}
                        />
                    </FlexItem2>
                </Row>
                <Row key={index}>
                    <FlexItem2>
                        <DropdownField
                            label='State'
                            options={stateOptions}
                            defaultValue={{
                                value: data.state,
                                label: data.state,
                            }}
                            onChange={(e) => handleChange(e, 'State', index, dataArray)}
                        />
                    </FlexItem2>
                    <FlexItem2>
                        <DropdownField
                            label='Country'
                            options={countryOptions}
                            defaultValue={{
                                value: data.country,
                                label: data.country,
                            }}
                            onChange={(e) => handleChange(e, 'Country', index, dataArray)}
                        />
                    </FlexItem2>
                    <FlexItem2>
                        <InputField
                            label='ZipCode'
                            onChange={(e) => handleChange(e, 'ZipCode', index, dataArray)}
                            textboxDefaultValue={data.zipCode}
                        />
                    </FlexItem2>
                    <FlexItem2>
                        <DropdownField
                            label='Right Of Representation'
                            options={rightOfRepresentationOptions}
                            defaultValue={{
                                value: data.rightOfRepresentation,
                                label: data.rightOfRepresentation,
                            }}
                            onChange={(e) => handleChange(e, 'Right Of Representation', index, dataArray)}
                        />
                    </FlexItem2>
                </Row>
            </>
        )
    }

    const getHeading = (label: string) => {
        return (
            <SubHeadingWrapper>
                <WDStyledSubSectionTitle>{label}</WDStyledSubSectionTitle>
            </SubHeadingWrapper>
        )
    }

    const getPlusSquareButton = (dataArray: BeneficiaryUpdate[]) => {
        return (
            <Row>
                <FlexItem>
                    <Tooltip label="Add Beneficiary" aria-label='Add Beneficiary' color='black' bg='white' sx={{ ml: '100px', mt: "5px" }}>
                        <PlusSquareIcon
                            color="green"
                            fontSize="20px"
                            marginLeft="7px"
                            onClick={() => addBeneficiary(dataArray)}
                            cursor='pointer'
                        /></Tooltip>
                </FlexItem>
            </Row>
        )
    }

    return (
        <>
            {getHeading(messages.beneficiary_ui.primary_title)}
            <DividerWrapper2>
                <Divider
                    bgColor={COLORS.Background.Border}
                    horizontal={true}
                    thickness="1px"
                />
            </DividerWrapper2>

            {primaryBeneficiaryData.map((data, index) => (
                <>
                    <Row key={index}>
                        <FlexItem2>Beneficiary {index + 1}</FlexItem2>
                        <FlexItem2></FlexItem2>
                        <FlexItem2></FlexItem2>
                        <FlexItem3>
                            <Tooltip label="Delete Beneficiary" aria-label='Delete Beneficiary' color='black' bg='white'
                                sx={{ ml: '-100px' }}>
                                <SmallCloseIcon
                                    color="red"
                                    fontSize="20px"
                                    onClick={() => deleteRecord(index, primaryBeneficiaryData)}
                                    cursor='pointer'
                                />
                            </Tooltip>
                        </FlexItem3></Row>
                    {addRow(index, data, primaryBeneficiaryData)}
                    {index + 1 === primaryBeneficiaryData.length &&
                        <>
                            <Row>
                                <FlexItem3>
                                    <LabelWrap3>Total &nbsp;</LabelWrap3>
                                    <InputField
                                        textboxDefaultValue={data.totalBenefits}
                                        textboxValue={totalPrimaryBenefits}
                                        onChange={() => console.log('')}
                                        disabled={true}
                                    />
                                </FlexItem3>
                            </Row>
                            <Row>
                                <FlexItem3>
                                    {totalPrimaryBenefitsErr
                                        && (
                                            <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                                                <Label
                                                    fontFamily={'SourceSansPro-Regular'}
                                                    fontSize={'14px'}
                                                    fontStyle={'normal'}
                                                    fontWeight={400}
                                                    lineHeight={'16px'}
                                                    color={'#BA0517'}
                                                >
                                                    {totalPrimaryBenefitsErr
                                                        ? messages.beneficiary_ui.total_benefits_error.total_invalid
                                                        : ''}
                                                </Label>
                                            </InlineErrorWrapper>
                                        )}
                                </FlexItem3>
                            </Row>
                        </>}
                    {index + 1 !== primaryBeneficiaryData.length &&
                        <DividerWrapper2>
                            <Divider
                                bgColor={COLORS.Background.Border}
                                horizontal={true}
                                thickness="1px"
                            />
                        </DividerWrapper2>}
                </>
            ))}
            {getPlusSquareButton(primaryBeneficiaryData)}



            {getHeading(messages.beneficiary_ui.contingent_title)}
            <DividerWrapper2>
                <Divider
                    bgColor={COLORS.Background.Border}
                    horizontal={true}
                    thickness="1px"
                />
            </DividerWrapper2>

            {contingentData.map((data, index) => (
                <>
                    <Row key={index}>
                        <FlexItem2> Contingent {index + 1}</FlexItem2>
                        <FlexItem2></FlexItem2>
                        <FlexItem2></FlexItem2>
                        <FlexItem3>
                            <Tooltip label="Delete a Beneficiary" aria-label='Delete a Beneficiary' color='black' bg='white'
                                sx={{ ml: '-100px' }}>
                                <SmallCloseIcon
                                    color="red"
                                    fontSize="20px"
                                    onClick={() => deleteRecord(index, contingentData)}
                                    cursor='pointer'
                                />
                            </Tooltip>
                        </FlexItem3>
                    </Row>
                    {addRow(index, data, contingentData)}
                    {index + 1 === contingentData.length &&
                        <>
                            <Row>
                                <FlexItem3>
                                    <LabelWrap3>Total &nbsp;</LabelWrap3>
                                    <InputField
                                        textboxDefaultValue={data.totalBenefits}
                                        textboxValue={totalContingentBenefits}
                                        onChange={() => console.log('')}
                                        disabled={true}
                                    />
                                </FlexItem3>
                            </Row>
                            <Row>
                                <FlexItem3>
                                    {totalContingentBenefitsErr
                                        && (
                                            <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                                                <Label
                                                    fontFamily={'SourceSansPro-Regular'}
                                                    fontSize={'14px'}
                                                    fontStyle={'normal'}
                                                    fontWeight={400}
                                                    lineHeight={'16px'}
                                                    color={'#BA0517'}
                                                >
                                                    {(
                                                        totalContingentBenefitsErr
                                                    )
                                                        ? messages.beneficiary_ui.total_benefits_error.total_invalid
                                                        : ''}
                                                </Label>
                                            </InlineErrorWrapper>
                                        )}
                                </FlexItem3>
                            </Row>
                        </>}
                    {index + 1 !== contingentData.length &&
                        <DividerWrapper2>
                            <Divider
                                bgColor={COLORS.Background.Border}
                                horizontal={true}
                                thickness="1px"
                            />
                        </DividerWrapper2>}
                </>
            ))}
            {getPlusSquareButton(contingentData)}

            <DividerWrapper2>
                <Divider
                    bgColor={COLORS.Background.Border}
                    horizontal={true}
                    thickness="1px"
                />
            </DividerWrapper2>

            <ButtonRow>
                <SecondaryButtonWrapper>
                    <WDStyledCancelButton onClick={cancelFunction}>
                        <WDStyledCancelButtonLabel>
                            {messages.account_details_ui.buttons.cancel}
                        </WDStyledCancelButtonLabel>
                    </WDStyledCancelButton>
                </SecondaryButtonWrapper>
                <PrimaryButtonWrapper>
                    <WDStyledSaveButton onClick={() => save()}>
                        <WDStyledCancelButtonLabel>
                            {messages.account_details_ui.buttons.save}
                        </WDStyledCancelButtonLabel>
                    </WDStyledSaveButton>
                </PrimaryButtonWrapper>
            </ButtonRow>
            {BeneficiaryDetailsValues?.submissionPopUp && (
                <BeneficiarySubmisionPage />
            )}
        </>
    )
}
