import React, { useContext, useState } from 'react'
import { BeneficiaryContext } from '../../services/beneficiaryContext/BeneficiaryContext'
import { SvgInvestment } from '../../components/svg/SvgInvestment'
import { WDInvestmentSubLabel, WDReadModeTitle, WDStyledSubSectionTitle, WDStyledSectionTitle, WDStyledEditButtonLabel } from '../../components/ui/WDTypography'
import { MasterWrapper, TopRow, HeaderRow, TitleIconWrap, DividerWrapper } from '../householdUI/styles'
import { IconWrapper } from '../Tag/styles'
import { SvgChevronUp } from '../../components/svg/SvgChevronUp'
import { SvgChevronDown } from '../../components/svg/SvgChevronDown'
import Divider from '../../components/divider/Divider'
import { COLORS } from '../../theme/Colors'
import { ApprovalContext } from '../../services/approvalContext/ApprovalContext'
import { FlexItem2, SubHeadingWrapper, Row, TertiaryButtonWrapper, EditWrapper } from '../investment/styles'
import {
    WDStyledCancelButton,
    WDStyledEditButton,
    WDStyledSaveButton,
} from '../../components/ui/WDButtons'
import { BeneficiaryEdit } from './BeneficiaryEdit'
import { messages } from '../../shared/constants'
import { SvgEye } from '../../components/svg/SvgEye'
import { SvgNoVisible } from '../../components/svg/SvgNoVisible'
import { LabelWrap } from './styles'

export const Beneficiary = () => {
    const beneficiaryFields = useContext(BeneficiaryContext)
    const approval = useContext(ApprovalContext)
    const [visibility, setVisibility] = useState(false)
    const [visibility1, setVisibility1] = useState(false)

    const collapse = () => {
        beneficiaryFields?.setBeneficiaryCollapse(
            !beneficiaryFields?.beneficiaryCollapse
        )
    }

    const formatSSNORTINView = (value: string) => {
        if (!value) return value
        const number = value
        const numberLength = number?.length
        if (numberLength < 4) return number
        if (numberLength < 6) {
            return `${number.slice(0, 3)}-${number?.slice(3, 5)}`
        }
        return `${number?.slice(0, 3)}-${number?.slice(3, 5)}-${number?.slice(5, 9)}`
    }

    const editFunction = () => {
        approval?.setIsActiveInv(!approval?.isActiveInv)
        beneficiaryFields?.setBeneficiaryCollapse(true)
    }

    return (
        <MasterWrapper id="beneficiary-designation">
            <TopRow>
                <HeaderRow onClick={() => collapse()}>
                    <TitleIconWrap>
                        <IconWrapper>
                            <SvgInvestment />
                        </IconWrapper>
                        <WDStyledSectionTitle>{'Beneficiary Designation'}</WDStyledSectionTitle>
                    </TitleIconWrap>
                </HeaderRow>
                <TertiaryButtonWrapper>
                    {!approval?.isActiveInv && (
                        <>
                            {!approval?.isActiveAct && !approval?.isActiveAd && !approval?.isActiveAl && (
                                <WDStyledEditButton onClick={() => editFunction()}>
                                    <WDStyledEditButtonLabel> {'Edit'}</WDStyledEditButtonLabel>
                                </WDStyledEditButton>
                            )}
                        </>
                    )}
                    <EditWrapper onClick={() => collapse()}>
                        {beneficiaryFields?.beneficiaryCollapse && <SvgChevronUp />}
                        {!beneficiaryFields?.beneficiaryCollapse && <SvgChevronDown />}
                    </EditWrapper>
                </TertiaryButtonWrapper>
            </TopRow>
            {beneficiaryFields?.beneficiaryCollapse && (
                <DividerWrapper>
                    <Divider
                        bgColor={COLORS.Background.Border}
                        horizontal={true}
                        thickness="1px"
                    />
                </DividerWrapper>
            )}
            {beneficiaryFields?.beneficiaryCollapse && (
                <>{!approval?.isActiveInv && (
                    <>
                        <SubHeadingWrapper>
                            <WDStyledSubSectionTitle>{'Primary Beneficiary (ies)'}</WDStyledSubSectionTitle>
                        </SubHeadingWrapper>
                        <Row></Row>
                        <DividerWrapper>
                            <Divider
                                bgColor={COLORS.Background.Border}
                                horizontal={true}
                                thickness="1px"
                            />
                        </DividerWrapper>
                        {messages.Beneficiary_Designation1.primary_data.map((data, index) => (
                            <>
                                <Row key={index}>
                                    Beneficiary {index + 1}
                                </Row>
                                <Row>
                                    <FlexItem2>
                                        <LabelWrap>
                                            <WDReadModeTitle>PartyId</WDReadModeTitle>
                                        </LabelWrap>
                                        <LabelWrap>
                                            <WDInvestmentSubLabel>{data.partyId}</WDInvestmentSubLabel>
                                        </LabelWrap>
                                    </FlexItem2>
                                    <FlexItem2>
                                        <LabelWrap>
                                            <WDReadModeTitle>Full Name</WDReadModeTitle>
                                        </LabelWrap>
                                        <LabelWrap>
                                            <WDInvestmentSubLabel>
                                                {data.fullName}
                                            </WDInvestmentSubLabel>
                                        </LabelWrap>
                                    </FlexItem2>
                                    <FlexItem2>
                                        <LabelWrap>
                                            <WDReadModeTitle>SSN/TIN</WDReadModeTitle>
                                        </LabelWrap>
                                        <LabelWrap>
                                            <IconWrapper onClick={() => setVisibility(!visibility)}>
                                                {!visibility && <SvgEye />}
                                                {visibility && <SvgNoVisible />}
                                            </IconWrapper>
                                            {!visibility && (
                                                <WDInvestmentSubLabel>
                                                    {data.ssnOrTin ? <>&bull;&bull;&bull;&nbsp;&bull;&bull;&bull;&nbsp;{data.ssnOrTin.slice(-4)}</> : <span style={{ paddingLeft: '-5px' }}>{'--'}</span>}
                                                </WDInvestmentSubLabel>
                                            )}
                                            {visibility && (
                                                <WDInvestmentSubLabel>{data.ssnOrTin ? (data.ssnOrTin) : <span style={{ paddingLeft: '15px' }}>{'--'}</span>}</WDInvestmentSubLabel>
                                            )}
                                        </LabelWrap>
                                    </FlexItem2>
                                    <FlexItem2>
                                        <LabelWrap>
                                            <WDReadModeTitle>Date of Birth</WDReadModeTitle>
                                        </LabelWrap>
                                        <LabelWrap>
                                            <WDInvestmentSubLabel>{data.dateOfBirth}</WDInvestmentSubLabel>
                                        </LabelWrap>
                                    </FlexItem2>
                                </Row>
                                <Row>
                                    <FlexItem2>
                                        <LabelWrap>
                                            <WDReadModeTitle>Relationship</WDReadModeTitle>
                                        </LabelWrap>
                                        <LabelWrap>
                                            <WDInvestmentSubLabel>{data.relationship}</WDInvestmentSubLabel>
                                        </LabelWrap>
                                    </FlexItem2>
                                    <FlexItem2>
                                        <LabelWrap>
                                            <WDReadModeTitle>% Benefits</WDReadModeTitle>
                                        </LabelWrap>
                                        <LabelWrap>
                                            <WDInvestmentSubLabel>{data.benefits}</WDInvestmentSubLabel>
                                        </LabelWrap>
                                    </FlexItem2>
                                    <FlexItem2>
                                        <LabelWrap>
                                            <WDReadModeTitle>Address Line 1</WDReadModeTitle>
                                        </LabelWrap>
                                        <LabelWrap>
                                            <WDInvestmentSubLabel>{data.addressLine1}</WDInvestmentSubLabel>
                                        </LabelWrap>
                                    </FlexItem2>
                                    <FlexItem2>
                                        <LabelWrap>
                                            <WDReadModeTitle>Address Line 2</WDReadModeTitle>
                                        </LabelWrap>
                                        <LabelWrap>
                                            <WDInvestmentSubLabel>{data.addressLine2}</WDInvestmentSubLabel>
                                        </LabelWrap>
                                    </FlexItem2>
                                </Row>
                                <Row>
                                    <FlexItem2>
                                        <LabelWrap>
                                            <WDReadModeTitle>City</WDReadModeTitle>
                                        </LabelWrap>
                                        <LabelWrap>
                                            <WDInvestmentSubLabel>{data.city}</WDInvestmentSubLabel>
                                        </LabelWrap>
                                    </FlexItem2>
                                    <FlexItem2>
                                        <LabelWrap>
                                            <WDReadModeTitle>State</WDReadModeTitle>
                                        </LabelWrap>
                                        <LabelWrap>
                                            <WDInvestmentSubLabel>{data.state}</WDInvestmentSubLabel>
                                        </LabelWrap>
                                    </FlexItem2>
                                    <FlexItem2>
                                        <LabelWrap>
                                            <WDReadModeTitle>Country</WDReadModeTitle>
                                        </LabelWrap>
                                        <LabelWrap>
                                            <WDInvestmentSubLabel>{data.country}</WDInvestmentSubLabel>
                                        </LabelWrap>
                                    </FlexItem2>
                                    <FlexItem2>
                                        <LabelWrap>
                                            <WDReadModeTitle>ZipCode</WDReadModeTitle>
                                        </LabelWrap>
                                        <LabelWrap>
                                            <WDInvestmentSubLabel>{data.zipCode}</WDInvestmentSubLabel>
                                        </LabelWrap>
                                    </FlexItem2>
                                </Row>
                                <Row>
                                    <FlexItem2>
                                        <LabelWrap>
                                            <WDReadModeTitle>Right Of Representation</WDReadModeTitle>
                                        </LabelWrap>
                                        <LabelWrap>
                                            <WDInvestmentSubLabel>{data.rightOfRepresentation}</WDInvestmentSubLabel>
                                        </LabelWrap>
                                    </FlexItem2>
                                    <FlexItem2></FlexItem2>
                                    <FlexItem2></FlexItem2>
                                    <FlexItem2></FlexItem2>
                                </Row>
                                {messages.Beneficiary_Designation1.primary_data.length > index + 1 &&
                                    <DividerWrapper>
                                        <Divider
                                            bgColor={COLORS.Background.Border}
                                            horizontal={true}
                                            thickness="1px"
                                        />
                                    </DividerWrapper>}
                            </>
                        ))}

                        <SubHeadingWrapper>
                            <WDStyledSubSectionTitle>{'Contingent Beneficiary (ies)'}</WDStyledSubSectionTitle>
                        </SubHeadingWrapper>
                        <Row></Row>
                        <DividerWrapper>
                            <Divider
                                bgColor={COLORS.Background.Border}
                                horizontal={true}
                                thickness="1px"
                            />
                        </DividerWrapper>
                        {messages.Beneficiary_Designation1.contingent_data.map((data, index) => (
                            <>
                                <Row key={index}>
                                    Contingent {index + 1}
                                </Row>
                                <Row>
                                    <FlexItem2>
                                        <LabelWrap>
                                            <WDReadModeTitle>PartyId</WDReadModeTitle>
                                        </LabelWrap>
                                        <LabelWrap>
                                            <WDInvestmentSubLabel>{data.partyId}</WDInvestmentSubLabel>
                                        </LabelWrap>
                                    </FlexItem2>
                                    <FlexItem2>
                                        <LabelWrap>
                                            <WDReadModeTitle>Full Name</WDReadModeTitle>
                                        </LabelWrap>
                                        <LabelWrap>
                                            <WDInvestmentSubLabel>
                                                {data.fullName}
                                            </WDInvestmentSubLabel>
                                        </LabelWrap>
                                    </FlexItem2>
                                    <FlexItem2>
                                        <LabelWrap>
                                            <WDReadModeTitle>SSN/TIN</WDReadModeTitle>
                                        </LabelWrap>
                                        <LabelWrap>
                                            <IconWrapper onClick={() => setVisibility1(!visibility1)}>
                                                {!visibility1 && <SvgEye />}
                                                {visibility1 && <SvgNoVisible />}
                                            </IconWrapper>
                                            {!visibility1 && (
                                                <WDInvestmentSubLabel>
                                                    {data.ssnOrTin ? <>&bull;&bull;&bull;&nbsp;&bull;&bull;&bull;&nbsp;{data.ssnOrTin.slice(-4)}</> : <span style={{ paddingLeft: '-5px' }}>{'--'}</span>}
                                                </WDInvestmentSubLabel>
                                            )}
                                            {visibility1 && (
                                                <WDInvestmentSubLabel>{data.ssnOrTin ? (data.ssnOrTin) : <span style={{ paddingLeft: '15px' }}>{'--'}</span>}</WDInvestmentSubLabel>
                                            )}
                                        </LabelWrap>
                                    </FlexItem2>
                                    <FlexItem2>
                                        <LabelWrap>
                                            <WDReadModeTitle>Date of Birth</WDReadModeTitle>
                                        </LabelWrap>
                                        <LabelWrap>
                                            <WDInvestmentSubLabel>{data.dateOfBirth}</WDInvestmentSubLabel>
                                        </LabelWrap>
                                    </FlexItem2>

                                </Row>
                                <Row>
                                    <FlexItem2>
                                        <LabelWrap>
                                            <WDReadModeTitle>Relationship</WDReadModeTitle>
                                        </LabelWrap>
                                        <LabelWrap>
                                            <WDInvestmentSubLabel>{data.relationship}</WDInvestmentSubLabel>
                                        </LabelWrap>
                                    </FlexItem2>
                                    <FlexItem2>
                                        <LabelWrap>
                                            <WDReadModeTitle>% Benefits</WDReadModeTitle>
                                        </LabelWrap>
                                        <LabelWrap>
                                            <WDInvestmentSubLabel>{data.benefits}</WDInvestmentSubLabel>
                                        </LabelWrap>
                                    </FlexItem2>
                                    <FlexItem2>
                                        <LabelWrap>
                                            <WDReadModeTitle>Address Line 1</WDReadModeTitle>
                                        </LabelWrap>
                                        <LabelWrap>
                                            <WDInvestmentSubLabel>{data.addressLine1}</WDInvestmentSubLabel>
                                        </LabelWrap>
                                    </FlexItem2>
                                    <FlexItem2>
                                        <LabelWrap>
                                            <WDReadModeTitle>Address Line 2</WDReadModeTitle>
                                        </LabelWrap>
                                        <LabelWrap>
                                            <WDInvestmentSubLabel>{data.addressLine2}</WDInvestmentSubLabel>
                                        </LabelWrap>
                                    </FlexItem2>

                                </Row>
                                <Row>
                                    <FlexItem2>
                                        <LabelWrap>
                                            <WDReadModeTitle>City</WDReadModeTitle>
                                        </LabelWrap>
                                        <LabelWrap>
                                            <WDInvestmentSubLabel>{data.city}</WDInvestmentSubLabel>
                                        </LabelWrap>
                                    </FlexItem2>
                                    <FlexItem2>
                                        <LabelWrap>
                                            <WDReadModeTitle>State</WDReadModeTitle>
                                        </LabelWrap>
                                        <LabelWrap>
                                            <WDInvestmentSubLabel>{data.state}</WDInvestmentSubLabel>
                                        </LabelWrap>
                                    </FlexItem2>
                                    <FlexItem2>
                                        <LabelWrap>
                                            <WDReadModeTitle>Country</WDReadModeTitle>
                                        </LabelWrap>
                                        <LabelWrap>
                                            <WDInvestmentSubLabel>{data.country}</WDInvestmentSubLabel>
                                        </LabelWrap>
                                    </FlexItem2>
                                    <FlexItem2>
                                        <LabelWrap>
                                            <WDReadModeTitle>ZipCode</WDReadModeTitle>
                                        </LabelWrap>
                                        <LabelWrap>
                                            <WDInvestmentSubLabel>{data.zipCode}</WDInvestmentSubLabel>
                                        </LabelWrap>
                                    </FlexItem2>

                                </Row>
                                <Row>
                                    <FlexItem2>
                                        <LabelWrap>
                                            <WDReadModeTitle>Right Of Representation</WDReadModeTitle>
                                        </LabelWrap>
                                        <LabelWrap>
                                            <WDInvestmentSubLabel>{data.rightOfRepresentation}</WDInvestmentSubLabel>
                                        </LabelWrap>
                                    </FlexItem2>
                                    <FlexItem2></FlexItem2>
                                    <FlexItem2></FlexItem2>
                                    <FlexItem2></FlexItem2>
                                </Row>
                                {messages.Beneficiary_Designation1.contingent_data.length > index + 1 &&
                                    <DividerWrapper>
                                        <Divider
                                            bgColor={COLORS.Background.Border}
                                            horizontal={true}
                                            thickness="1px"
                                        />
                                    </DividerWrapper>}
                            </>
                        ))}
                    </>
                )}</>
            )}
            {/* Edit Mode */}
            {beneficiaryFields?.beneficiaryCollapse && (
                <>
                    {approval?.isActiveInv && <BeneficiaryEdit />}
                </>
            )}
        </MasterWrapper>
    )


}


