import { AxiosInstance } from 'axios'
import { BeneficiaryUpdate } from '../../services/beneficiaryContext/BeneficiaryContext'
import { URLs } from '../../services/httpConfiguration'

export async function fetchBeneficiaryDetails(
  accountId: string,
  axiosProtected: AxiosInstance
) {
  const url = URLs.GET_BENEFICIARY_DETAILS
  const payload = {
    accountNumber: accountId,
  }
  const response = await axiosProtected.post(url, payload)
  const data = response.data
  return data
}

// export async function accountDetailsSubmissionMethod(
//   payload: BeneficiaryUpdate[] | null,
//   axiosProtected: AxiosInstance
// ) {
//   try{
//   const url = URLs.ACCOUNT_DETAILS_SUBMISSION
//   const response = await axiosProtected.post(url, payload)
//   const data = response.status
//   // console.log('🚀 ~ Update response =>', response)
//   return data
//   }catch(e){
//     return 500
//   }
// }